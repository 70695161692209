<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic</div>
                    <div class="card-body">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Page</a></li>
                                <li class="breadcrumb-item"><a href="#">Page02</a></li>
                                <li class="breadcrumb-item"><a href="#">Page03</a></li>
                                <li aria-current="page" class="breadcrumb-item active">Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Advanced / Custom</div>
                    <div class="card-body">
                        <nav aria-label="breadcrumb">
                            <custom-breadcrumb class="mr-4">
                                <li aria-current="page" class="breadcrumb-item active">Page</li>
                            </custom-breadcrumb>

                            <custom-breadcrumb class="mr-4">
                                <li class="breadcrumb-item"><a href="#">Page</a></li>
                                <li aria-current="page" class="breadcrumb-item active">Page</li>
                            </custom-breadcrumb>

                            <custom-breadcrumb active>
                                <li class="breadcrumb-item"><a href="#">Page</a></li>
                                <li class="breadcrumb-item"><a href="#">Page</a></li>
                                <li aria-current="page" class="breadcrumb-item active">Page</li>
                            </custom-breadcrumb>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomBreadcrumb from "../../../src/components/CustomBreadcrumb/CustomBreadcrumb";

    export default {
        name: 'Breadcrumb',
        components: {
            CustomBreadcrumb
        }
    }
</script>
