<template>
    <ol class="breadcrumb breadcrumb-custom">
        <li class="breadcrumb-item">
            <div class="ellipsis-container">
                <a :class="['breadcrumb-ellipsis', {'hover':active}]" href="#">
                    <fa icon="ellipsis-h"></fa>
                </a>
            </div>
        </li>
        <slot></slot>
    </ol>
</template>

<script>
    export default {
        name: "CustomBreadcrumb",
        props: {
            active: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
